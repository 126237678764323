<template>
  <div class="select-boundary">
    <Loading :is_loading="loading" message="Loading Locations..." />
      <form name="form" @submit.prevent="submitBoundary" v-if="!loading">
        <div class="mb-5">
          <label for="state">Please Select a State</label>
          <div class="flex-row-centered">
            <b-form-radio-group v-model="state" :options="stateOptions" name="state" buttons v-validate="'required'"
            @input="filterLocationsByState"
            >
            </b-form-radio-group>
          </div>
          <div
              v-if="errors.has('state')"
              class="alert alert-danger"
              role="alert"
          >
            State is required.
          </div>
        </div>

        <div class="flex-col-centered">
          <label for="lga">Please Select an LGA from the Dropdown</label>
          <div class="flex-col-centered w-50">
            <b-input-group>
                <b-input-group-prepend class="search-icon-container">
                    <font-awesome-icon class="search-icon" icon="search" size="2x"></font-awesome-icon>
              </b-input-group-prepend>
              <b-form-input class="search-box" type="search" debounce="500" placeholder="Search" v-model="searchText"
              ></b-form-input>
            </b-input-group>

            <b-form-select :options="filteredLocations" id="lga" aria-placeholder="location" name="lga"
                           placeholder="Select Location"
                           v-model="selectedLocation" v-validate="'required'" @change="selectLocation"
                            ref="locationSelect"
            > </b-form-select>
          </div>
          <div
              v-if="errors.has('lga')"
              class="alert alert-danger"
              role="alert"
          >
            Location/LGA is required.
          </div>
        </div>

        <div class="flex-row-centered">
          <button class="rebutton" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Select Datasets</span>
          </button>
        </div>
      </form>
  </div>
</template>

<script>

import Loading from "./Loading";

export default {
  name: 'SelectBoundary',
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      state: null,
      stateOptions: [
        {text: 'NSW', value: 'New South Wales'},
        {text: 'NT', value: 'Northern Territory'},
        {text: 'QLD', value: 'Queensland'},
        {text: 'SA', value: 'South Australia'},
        {text: 'TAS', value: 'Tasmania'},
        {text: 'VIC', value: 'Victoria'},
        {text: 'WA', value: 'Western Australia'}
      ],
      locations: [],
      // filteredLocations: [],
      selectedLocation: null,
      searchText: null
    };
  },
  computed: {
    filteredLocations() {
      if (this.searchText) {
        return this.locations.filter(x => x.toUpperCase().includes(this.searchText.toUpperCase()))
      } else {
        return this.locations
      }
    }
  },
  async created() {
    if (!this.$store.state.query.locations) {
      await this.$store.dispatch('query/getBoundaries')
    }
    if (this.$store.state.query.locations.length === 0) {
      this.locations = ['Location']
    } else {
      this.locations = this.$store.state.query.locations.map(x => x.name).sort()
    }
    this.loading = false
  },
  methods: {
    selectLocation(value) {
      if (!this.state) {
        let location = this.$store.state.query.locations.find(x => x.name === value)
        this.state = location.state_name
        this.filterLocationsByState(this.state)
      }
    },
    filterLocationsByState(state) {
      this.locations = this.$store.state.query.locations.filter(x => x.state_name === state).map(x => x.name)
    },
    submitBoundary() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        this.loading = false
        this.$store.commit('query/selectLocation', this.selectedLocation)
        this.$router.push('/home/query');
        // if (this.user.username && this.user.password) {
        //   this.$store.dispatch('auth/login', this.user).then(
        //     () => {
        //       this.$router.push('/home');
        //     },
        //     (error) => {
        //       this.loading = false;
        //       this.message =
        //         (error.response && error.response.data) ||
        //         error.message ||
        //         error.toString();
        //     }
        //   );
        // }
      });
    }
  },
  watch: {
    filteredLocations (locations) {
      // If filteredLocations changes AND there was search text, select the first result
      // NOTE: I'd rather trigger the dropdown to expand, but it seems that isn't possible (?!)
      if (locations && this.searchText) {
        this.selectedLocation = locations[0]
      }
    }
  }
};
</script>

<style scoped>

.select-boundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10%
}

.flex-row-centered {
  margin-top: 2em;
}


label {
  text-align: center;
  display: block;
  margin-top: 10px;
  color: #026cc0;
  font-size: 1.1em;
  margin-bottom: 5%;
}

.search-box {
  border-left: none;
  margin-left: 0;
  padding-left: 0;
}

.search-icon-container {
  width: 48px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-right: none;
}

.search-icon {
  display: inline-block;
  width: 100%;
  border-right: none;
  color: #707070;
  font-size: 1.8em;
  text-align: center;
  line-height: 1.2;
}

.footer {
  display: block;
  position: fixed;
  left: 75%;
  transform: translate(-50%, 0);
  bottom: 7.5%;
  text-align: center;
  font-size: 0.75em;
}

</style>
